<template>
  <div>
    <!--begin::Head-->
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>Sign In</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <b-alert :show="isInvalidLogin" variant="danger">{{
            $tc("AUTH.LOGIN.ERRORS.INVALID_CREDENTIALS")
          }}</b-alert>

          <router-link
            v-if="isPasswordExpired"
            :to="{ name: 'forgot_password' }"
          >
            <b-alert show variant="danger">{{
              $tc("AUTH.LOGIN.ERRORS.LOGIN_EXPIRED_PASSWORD")
            }}</b-alert>
          </router-link>

          <b-alert v-if="isAccountBlocked" show variant="danger"
            ><span v-html="messageError"></span
          ></b-alert>

          <b-form-group id="login" label="" label-for="login">
            <b-form-input
              id="email"
              name="email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              placeholder="Email"
              aria-describedby="input-1-live-feedback"
              autocomplete="email"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              Email is required and a valid email address.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="example-input-group-2"
            label=""
            label-for="example-input-2"
          >
            <b-form-input
              type="password"
              id="password"
              name="password"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              placeholder="Password"
              aria-describedby="input-2-live-feedback"
              autocomplete="current-password"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback">
              Password is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <a href="#/forgot-password" class="kt-link kt-login__link-forgot">
              {{ $t("AUTH.LOGIN.FORGOT") }}
            </a>

            <b-button
              :disabled="!!disabled"
              type="submit"
              size="sm"
              class="btn btn-primary btn-elevate kt-login__btn-primary"
              style="width: 160px"
            >
              <strong>{{ $t("AUTH.LOGIN.BUTTON") }} </strong>
              <b-spinner
                v-if="isLoading"
                small
                type="grow"
                variant="light"
                class="ml-auto"
              ></b-spinner>
            </b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { LOGIN, LOGOUT } from "@/store/auth.module";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "Login",

  mixins: [validationMixin],

  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      },
      isInvalidLogin: false,
      isPasswordExpired: false,
      isAccountBlocked: false,
      messageError: "",
      disabled: true,
      isLoading: false
    };
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },

  mounted() {
    if (this.$route.query.auth === "expired") {
      this.$bvToast.toast("Your session has expired, please login again", {
        title: "Session expired",
        variant: "danger",
        solid: true
      });
    }
    if (this.$route.query.register === "1") {
      this.$bvToast.toast(
        "Your account has been successfully created. Your can now sign-in to it.",
        {
          title: "Your account is ready!",
          variant: "success",
          solid: true
        }
      );
    }
  },

  watch: {
    form: {
      handler(form) {
        const emailValidation = 0 < form.email.length;
        const pwdValidation = 0 < form.password.length;

        this.disabled = !(emailValidation && pwdValidation);
      },
      deep: true
    }
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onSubmit() {
      this.$v.form.$touch();
      this.isInvalidLogin = false;
      this.isPasswordExpired = false;
      this.isAccountBlocked = false;
      this.messageError = "";
      this.disabled = true;
      this.isLoading = true;

      if (this.$v.form.$anyError) {
        this.disabled = false;
        this.isLoading = false;
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // send login request
      this.$store
        .dispatch(LOGIN, { email, password })
        // go to which page after successfully login
        .then(() => this.$router.push({ name: "dashboard" }))
        .catch(error => {
          const textError = error?.data?.message || "Something was wrong.";

          this.isAccountBlocked = true;
          this.messageError = textError;
          this.disabled = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    }
  }
};
</script>

<style scoped>
button:disabled {
  color: #ffffff;
}
button:hover {
  color: #e4e4e4;
}
</style>
